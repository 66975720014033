// Variables
@import "_variables";

@mixin form-border-radius {
    border-radius: 4px;
}


/* GLOBAL STYLES */

body .gform_wrapper .gform_body .gform_fields .gfield{
  input,textarea,select{
    @include form_border-radius;
    border: 1px $primary_color solid;
    font-size: 0.9rem;
  }
  label.gfield_label{
    color: $q92_blue;
    font-size: 1.1rem;
  }
  label{
    color: $primary_color;
    font-weight: 400;
  }
  &.inline-options{
    li{
      display: inline-block;
      padding-right: 10px!important;
      @media(max-width: 767px){
        width: 100%;
      }
    }
  }

}


/* END GLOBAL STYLES */

body .gform_wrapper .gform_body .gform_fields .gfield {
  .ginput_container {

  }

  .gfield_label {
    color: $primary_color;
    .gfield_required {

    }
  }
  .gfield_description {

  }
  input[type=text] {

  }
  textarea {

  }
  select{

  }
  .gfield_radio{
    li{

      input[type=radio]{
        margin-top: 0;
        margin-right: 5px;
      }
    }
  }
  .gfield_checkbox {
    li{
      input[type=checkbox]{
        margin-top: 0;
        margin-right: 5px;
      }
    }
  }
  .ginput_complex {

    .ginput_left {

      label{

      }
    }
    .ginput_right{

      label{

      }
    }
    .ginput_full{

      label{

      }
    }
  }
  .name_first {

    label{

    }
    input{

    }
  }
  .name_last{

    label{

    }
    input{

    }
  }
  input[type=email] {

  }
  input[type=tel] {

  }
  /* ADDRESS */
  .address_line_1 {
    input{

    }
    label{

    }
  }
  .address_line_2 {
    input{

    }
    label{

    }
  }
  .address_city {
    input{

    }
    label{

    }
  }
  .address_state {
    input{

    }
    label{

    }
  }
  .address_zip {
    input{

    }
    label{

    }
  }
  .address_country {
    select{

    }
    label{

    }
  }
  /*DATE/TIME*/
  .gfield_time_hour {
    input{

    }
    label{

    }
  }
  .gfield_time_minute {
    input{

    }
    label{

    }
  }
  .gfield_time_ampm{
    select{

    }
  }
  .datepicker {

  }
  .gfield_input_datepicker_icon {

  }
  .gfield_date_month {
    input{

    }
    label{

    }
  }
  .gfield_date_day {
    input{

    }
    label{

    }
  }
  .gfield_date_year {
    input{

    }
    label{

    }
  }


  /* FILES */
  .gform_fileupload_multifile {

  }
  .gform_drop_area {

  }
  .gform_button_select_files {

  }


  /* SECTIONS*/
  .gsection {
    .gsection_title {

    }
    .gsection_description {

    }
  }
}

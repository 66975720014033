// Variables
@import "_variables";

.sports-stream{
  border-top:5px #FFF solid;
}
.sports-stream .content-container{
  background: #EEE;
  padding: 20px;
  border-radius: 5px;
}
.sports-stream i{
  font-size: 1.4rem;
}
.sports-stream .btn{
  padding: 5px 15px;
}
.sports-billboard{
  p{
    font-size: 1.4rem;
    color: #FFFFFF;
  }
  .roosters-logo{
    padding: 10px;
    max-width: 60%;
    background: rgba(255,255,255,0.4);
    border-radius: 10px;
    margin: auto;
    -webkit-transition-duration: .5s; /* Safari */
    transition-duration: .5s;
    &:hover{
      background: rgba(255,255,255,0.5);

    }
    img{
      &:hover{
        box-shadow: none!important;
      }
      margin: auto;
    }
  }
}

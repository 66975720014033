$primary_color: #666666;
$secondary_color: #4FC5D3;
$q92_blue: #0055a5;
$comp_color: #EC008C;
$body_text_color: #000000;
$dark_button_color: #414042;
$purple: #7F4E9F;
$gray_headline: #58595B;
$headline_3_color: #3387C8;
$global_font:  'Roboto', serif;
$header_font:  'Josefin Sans', sans-serif;
$emphasis_font: 'Rubik', sans-serif;
$brand_font_1: 'Fredericka the Great', cursive;
$brand_font_2: 'adobe-caslon-pro', serif;
